<template>
    <div class="active modal-register">
        <div class="container-modal form-modal">
            <div class="container-holder">
                <div class="header-modal">
                    <div class="icon"><icon-lock-bold /></div>
                    <div class="title" v-html="$t('forgot_password.title')"></div>
				    <div class="description">{{ $t('forgot_password.description') }}</div>
                </div>

                <div class="form-section-register">
                    <div class="input-login-group-tbf w-tbf-100" v-bind:class="{has_error: $v.email.$error, complete: email != '' && !$v.email.$error}">
                        <div class="input-box">
                            <div class="icon-right-new"><icon-email /></div>
                            <input type="email" :placeholder="$t('general.email-placeholder')" class="input-text" v-model="email">
                        </div>
                    </div>
                </div>
                
                <div class="submit-footer">
                    <div class="submit-form">
                        <button id="buttonSubmit" class="button-submit" @click="resetPassword">
                            <div class="loader-spin" v-if="loadingSubmit"></div>
                            <div class="text" v-else>{{$t('forgot_password.button-action')}}</div>
                        </button>
                    </div>

                    <div class="bottom-links">
                        <router-link :to="{path: '/login'}">{{ $t('login.back_to_login') }}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconLockBold from '../Icons/LockBold'
import IconEmail from '../Icons/Email'

import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
	data(){
		return {
            email: "",
            loadingSubmit: false
		}
    },
    components: {
        IconLockBold,
        IconEmail
    },
    validations: {
		email: {required, email},
	},
	methods: {
        resetPassword() {
            var buttonName = 'buttonSubmit'
            var btnSubmit = document.getElementById(buttonName);
            
            btnSubmit.disabled = true
            this.loadingSubmit = true

            this.$v.$touch()
            if(this.$v.$invalid){
                btnSubmit.disabled = false
                this.loadingSubmit = false
            }else{
                axios.post("/auth/reset-password", {email: this.email})
                .then(result => {
                    this.$router.push({ name: 'password-forgot-success', query: { email: this.email }});
                }).catch(error => {
                    btnSubmit.disabled = false
                    this.loadingSubmit = false
                });
            }
        },
        redirect() {
            this.$router.push("/login");
        }
	}
}
</script>

<style lang="scss">
    .forgot-modal{
        .content-modal{
            .action-form{
                margin-top: 0;
            }
        }
    }
</style>